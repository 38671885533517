<template>
  <basic-block :isShowTitle="false" class="leavel_scholl">
    <!-- <template #search>
      <el-select
        :size="$publicConfig.selectSize"
        v-model="value"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </template> -->
    <div class="item_info">
      <div class="contents">
        <div class="content">
          <div class="avater">
            <img
              src="/aaw/img/attendance.c00e5aff.png"
              width="100%"
              alt=""
              srcset=""
              height="100%"
            />
          </div>
          <div class="info">
            <div class="name">{{ $cftY().split(' ')[0] }}</div>

            <div class="number">
              <span class="label">已放学班级</span>
              <span style="color: #00c9d9">{{ detail.afterSchoolCount }}</span>
              <span class="label">个</span>
              <span class="interval"></span>
              <span class="label">未放学班级</span>
              <span style="color: #ff4d4f">{{
                detail.unAfterSchoolCount
              }}</span>
              <span class="label">个</span>
            </div>

            <div>
              <span class="label">共计留校学生</span>
              <span style="color: #333333">{{
                detail.staySchoolStudentCount
              }}</span>
              <span class="label">人，其中已打卡离校</span>
              <span style="color: #333333">{{ detail.leaveSchoolCount }}</span>
              <span class="label">人；</span>
              <span class="label">共计托管学生</span>
              <span style="color: #333333">{{ detail.nurseryCount }}</span>
              <span class="label">人，其中已打卡离校</span>
              <span style="color: #333333">{{
                detail.nurseryAfterSchoolCount
              }}</span>
              <span class="label">人</span>
            </div>
          </div>
        </div>
        <div>
          <el-button
            :size="$publicConfig.buttonSize"
            type="primary"
            icon="el-icon-setting"
            @click="dialogTableVisible = true"
            >设置留校原因</el-button
          >
        </div>
      </div>
    </div>
    <div class="select_main">
      <el-row :gutter="12" class="pos_relative">
        <el-col :span="4">
          <el-date-picker
            :size="$publicConfig.selectSize"
            v-model="search.date"
            type="date"
            style="width: 100%"
            placeholder="选择日期"
            @change="getLists"
            format="yyyy-MM-dd"
            value-format="timestamp"
            :clearable="false"
          >
          </el-date-picker>
        </el-col>

        <el-col :span="4">
          <el-select
            v-model="search.campusCode"
            :size="$publicConfig.selectSize"
            placeholder="请选择校区"
            @change="getschool"
          >
            <el-option
              v-for="item in allschool"
              :key="item.campusCode"
              :label="item.campusName"
              :value="item.campusCode"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-select
            v-model="search.gradeId"
            :size="$publicConfig.selectSize"
            placeholder="请选择年级"
            @change="getGrade"
          >
            <el-option
              v-for="item in allGrade"
              :key="item.gradeCode"
              :label="item.grade"
              :value="item.gradeCode"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-select
            v-model="search.classCode"
            :size="$publicConfig.selectSize"
            placeholder="请选择班级"
            @change="getLists"
          >
            <el-option
              v-for="item in allClass"
              :key="item.classCode"
              :label="item.className"
              :value="item.classCode"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-select
            v-model="search.status"
            :size="$publicConfig.selectSize"
            placeholder="请选择状态"
            @change="getLists"
          >
            <el-option
              v-for="item in allStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-button
          class="abs"
          type="primary"
          size="mini"
          @click="exportLeaveSchoolStatic"
          >导出放学统计</el-button
        >
      </el-row>
    </div>
    <div class="content_table">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="grade" label="年级"></el-table-column>
        <el-table-column prop="className" label="班级"></el-table-column>
        <el-table-column prop="status" label="放学状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == '未放学'" type="danger"
              >未放学</el-tag
            >
            <el-tag v-else>已放学</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="inSchoolCount" label="在校生"> </el-table-column>
        <el-table-column prop="time" label="已放学/总人数">
          <template slot-scope="scope">
            {{ scope.row.afterSchoolCount }}/{{ scope.row.allCount }}
          </template>
        </el-table-column>
        <el-table-column prop="time" label="已放学/留校">
          <template slot-scope="scope">
            {{ scope.row.afterSchoolStayCount }}/{{ scope.row.staySchoolCount }}
          </template>
        </el-table-column>
        <el-table-column prop="time" label="已放学/托管">
          <template slot-scope="scope">
            {{ scope.row.afterSchoolNurseryCount }}/{{ scope.row.nurseryCount }}
          </template>
        </el-table-column>
        <el-table-column prop="time" label="请假">
          <template slot-scope="scope">
            {{
              scope.row.allCount -
              scope.row.inSchoolCount -
              scope.row.afterSchoolCount
            }}
          </template>
        </el-table-column>
        <el-table-column prop="time" label="放学时间"></el-table-column>
        <el-table-column prop="teacherName" label="操作人"></el-table-column>
        <!-- <el-table-column prop="staySchoolCount" label="留校人数"></el-table-column> -->
        <!-- <el-table-column prop="name" label="离班打卡记录"></el-table-column> -->
        <el-table-column prop="name" label="操作">
          <template slot-scope="scope">
            <el-link type="primary" @click="goto(scope.row)">点击查看</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page.sync="search.page"
        :page-size="search.size"
        @current-change="currentChange"
        :hide-on-single-page="false"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="设置留校原因"
      :visible.sync="dialogTableVisible"
      custom-class="my_pop"
      width="800px"
    >
      <el-row :gutter="10">
        <el-col :span="4">
          <el-tag
            style="width: 100px; margin: 6px 12px 6px 0; text-align: center"
            >自定义</el-tag
          >
        </el-col>

        <template v-for="(v, k) in reasons">
          <el-col :span="4" :key="k">
            <el-tag
              @close="tagClose(k)"
              style="width: 100px; margin: 6px 12px 6px 0; text-align: center"
              placeholder=""
              closable
              :disable-transitions="true"
              @click="itemClick(v, k)"
              v-if="!v.isEdit"
            >
              <span class="name">{{ v.reason }}</span>
            </el-tag>
            <el-input
              style="width: 100px; margin: 6px 12px 6px 0"
              class="input-new-tag"
              v-else
              v-model="v.reason"
              size="small"
              :ref="'saveTagInput' + k"
              @keyup.enter.native="v.isEdit = false"
              @blur="v.isEdit = false"
            >
            </el-input>
          </el-col>
        </template>
        <el-col :span="4">
          <el-input
            style="width: 100px; margin: 6px 12px 6px 0"
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <!-- <el-input size="mini"  style="width: 100px;margin: 6px 12px 6px 0;height: 32px !important;"  v-for="(v, k) in reasons" :key="k" v-model="v.name" placeholder=""></el-input> -->
          <el-link
            type="primary"
            v-if="!inputVisible"
            style="margin: 6px 12px 6px 0; height: 32px; line-height: 32px"
            @click="showInput"
            >添加原因</el-link
          >
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitReason">确 定</el-button>
      </div>
    </el-dialog>
  </basic-block>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getList,
  gettoDayCount,
  getReason,
  submitReason,
  exportLeaveSchool
} from '@/http/leaveSchool'
import { formatDate } from '@/utils'

export default {
  data() {
    return {
      reasons: [],
      inputValue: '',
      dialogTableVisible: false,
      dialogFormVisible: false,
      inputVisible: false,
      search: {
        date: new Date(new Date(new Date().toLocaleDateString())).getTime(),
        gradeId: '',
        classCode: '',
        status: '',
        page: 1,
        size: 10,
        campusCode: ''
      },
      detail: {},
      total: 0,
      tableData: [],
      allStatus: [
        {
          label: '全部状态',
          value: ''
        },
        {
          label: '未放学',
          value: 0
        },
        {
          label: '已放学',
          value: 1
        }
      ],
      allGrade: [],
      allClass: []
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'allNGrade', 'allNClass']),
    allschool() {
      return [
        {
          campusCode: '',
          campusName: '全部校区'
        },
        ...this.allNGrade
      ]
    }
    // allClass() {
    //   if(!this.search.gradeId) {
    //     return [
    //       {
    //         className: "全部班级",
    //         classCode: "",
    //       },
    //     ];
    //   }
    //   return [
    //     {
    //       className: "全部班级",
    //       classCode: "",
    //     },
    //     ...this.allNClass.filter(
    //       (item) => item.gradeInfo.gradeCode == this.search.gradeId
    //     ),
    //   ];
    // },
  },
  components: {},
  async created() {
    await this.teacherGetSchoolTerm()
    this.getLists()
    this.getReason()
  },
  methods: {
    async teacherGetSchoolTerm() {
      gettoDayCount({ schoolId: this.userInfo.tnId }).then((res) => {
        var num = 0
        this.allNClass.forEach((item) => {
          item.gradeList.forEach((items) => {
            num += items.classList.length
          })
        })
        res.data.unAfterSchoolCount = num
        this.detail = res.data
      })
    },
    getReason() {
      getReason({ schoolId: this.userInfo.tnId }).then((res) => {
        this.reasons = res.data.map((item) => {
          item.isEdit = false
          return item
        })
      })
    },
    submitReason() {
      let params = {
        schoolId: this.userInfo.tnId,
        reason: this.reasons.map((item) => {
          return item.reason
        })
      }
      submitReason(params).then((res) => {
        if (res.retCode == 1) {
          this.$message.success('设置成功')
          this.getReason()
          this.dialogTableVisible = false
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    currentChange(e) {
      this.search.page = e
      this.getLists()
    },
    getLists() {
      this.search.schoolId = this.userInfo.tnId
      getList(this.search).then((res) => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    //校区选择
    getschool(e) {
      this.search.page = 1
      this.allGrade = []
      this.allGrade.push({
        grade: '全部年级',
        gradeCode: ''
      })
      this.allschool.forEach((item) => {
        if (e == item.campusCode) {
          item.gradeList.forEach((items) => {
            this.allGrade.push(items)
          })
        }
      })

      this.getLists()
    },
    // 年级选择
    getGrade(e) {
      this.search.page = 1
      this.allClass = []
      this.allClass.push({
        className: '全部班级',
        classCode: ''
      })
      this.allGrade.forEach((item) => {
        if (e == item.gradeCode) {
          item.classList.forEach((items) => {
            this.allClass.push(items)
          })
        }
      })
      this.getLists()
    },
    goto(v) {
      var campusCode = ''
      this.allNGrade.forEach(item=>{
        item.gradeList.forEach(items=>{
          if(items.gradeCode === v.gradeId){
            campusCode = items.campusCode
          }
        })
      })
      console.log(v)
      this.$router.push({
        path: '/attendance/leaveSchoolDetail',
        query: {
          v:JSON.stringify(v),
          campusCode
        }
      })
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.reasons.push({
          reason: this.inputValue
        })
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    itemClick(v, k) {
      v.isEdit = true
      setTimeout(() => {
        this.$refs['saveTagInput' + k][0].$refs.input.focus()
      }, 500)
    },
    tagClose(k) {
      this.reasons.splice(k, 1)
    },

    // 导出放学统计
    exportLeaveSchoolStatic() {
      exportLeaveSchool({
        schoolId: this.userInfo.tnId,
        ...this.search
      }).then((res) => {
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel'
        })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob)
        downloadElement.href = href
        downloadElement.download = `放学统计结果${formatDate(
          this.search.date,
          'yyyy-MM-dd'
        )}.xlsx`
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
        this.myLoading.close()
        this.$message.success('导出成功')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.leavel_scholl {
  .select_main {
    margin: 24px 0 14px 0;
  }
  .my_pop {
    .el-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 60px;
      }
    }
  }

  .item_info {
    width: 100%;
    height: 145px;
    background: #f7f8fa;
    border-radius: 2px;
    .contents {
      display: flex;
      justify-content: space-between;
      padding: 10px 47px;
    }
    .content {
      display: flex;
      height: 100%;
      .avater {
        width: 127px;
        height: 120px;
        margin-right: 44px;
      }
      .info {
        font-size: 14px;
        color: #333333;
        span {
          display: inline-block;
        }
        .interval {
          width: 20px;
        }
        .name {
          font-size: 22px;
          font-weight: 500;
          color: #333333;
        }
        .number {
          margin: 18px 0 12px 0;
        }
        .label {
          color: #999999;
        }
      }
    }
  }
  .content_table {
  }
  .pagination {
    margin-top: 12px;
    width: 100%;
    text-align: right;
    right: 24px;
    bottom: 0;
    height: 40px;
    line-height: 40px;
  }
}

.pos_relative {
  position: relative;
}
.abs {
  position: absolute;
  right: 10px;
}
</style>